/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';

function DoPaper() {

  useEffect(() => {
    window.location.href = "https://apps.apple.com/hk/app/dopaper/id6504157589";
  }, [])

  return (
    <div />
  );
}

export default DoPaper;
