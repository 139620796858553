/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import demo1Image from "assets/images/dopaper_demo1.png";
import demo2Image from "assets/images/dopaper_demo2.png";
import demo3Image from "assets/images/dopaper_demo3.png";

export default [
  {
    image: demo1Image,
    name: "",
    route: "https://bit.ly/dopaper_aws_promotion",
    i: 0,
  },
  {
    image: demo2Image,
    name: "",
    route: "https://bit.ly/dopaper_aws_promotion",
    i: 1,
  },
  {
    image: demo3Image,
    name: "",
    route: "https://bit.ly/dopaper_aws_promotion",
    i: 2,
  },
];
